import React, { useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import { useQuery } from '@apollo/client';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import { VerticalCardList } from '../CardList';
import { LAYERS, mapSettings, sortTypes, summaryItems } from './constants';
import DownloadButton from './downloadButton';
import Filters from './Filters';
import { COUNTRIES_QUERY } from './operations';
import InfoOverlay from '../InfoOverlay';
import LeadAnalyst from '../LeadAnalyst';
import Links from './Links';
import Map from '../Map';
import SummaryList from '../SummaryList';
import { formatDateShort, getLatLongFromPoint } from '../../utils/helpers';
import { ProductionLineGraphProvider } from '../PortfolioAnalysis/PortfolioGraphing/LineGraphs';
import ProductionLineGraph from './productionLineGraph';
import { getNewPortfolio } from './Links/utils';
import { TOTAL_PRODUCTION_HEAT_MAP } from '../Map/Settings/constants';
import { getFulltextResultParametersFromSearch } from '../../utils/globalSearch';

import { GLOBAL_SEARCH_ACTION_DETAIL } from '../../utils/constants';
import { useGlobalSearchParameters } from '../../utils/globalSearch';

const defaultFilters = {
  regions: [],
  countries: [],
};

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.sizes.fullPage,
    flexWrap: 'nowrap',
    overflow: 'hidden',
  },
  infoOverlayRoot: {
    width: 'calc(100% - 305px)',
    left: '305px',
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
  },
  infoOverlayContent: {
    height: '100%',
  },
  filters: {
    backgroundColor: 'transparent',
    margin: 0,
  },
  cardList: {
    ...theme.palette.icedBackground,
    ...theme.mapPanel,
    padding: '0 10px 10px 10px',
  },
  overViewItem: {
    position: 'relative',
    padding: '0 10px',
    height: '100%',
  },
  scroll: {
    ...theme.palette.altScrollbar,
    overflow: 'auto',
  },
  divider: {
    ...theme.palette.transparentBackgroundAccent,
    width: '100%',
    height: '2px',
    margin: '20px 0',
  },
}));

const getDefaultFilters = () => {
  const filters = JSON.parse(sessionStorage.getItem('countryOverviewFilters'));
  if (
    filters &&
    Object.keys(defaultFilters).every((filter) => Object.keys(filters).includes(filter))
  ) {
    return filters;
  }
  return defaultFilters;
};

let overviewShouldOpen = false;

const MAP_ICON_SPACE_25 = 25;

export default () => {
  const classes = useStyles();

  const [map, setMap] = useState(null);
  const [selectedCountry, setCountry] = useState(null);
  const [clickedCountry, setClickedCountry] = useState(null);
  const [filters, setFilterState] = useState(getDefaultFilters());
  const [overViewOpen, setOverviewOpen] = useState(false);
  const [sortType, setSortType] = useState(sortTypes[0]);
  const [filtersHeight, setFiltersHeight] = useState(0);

  const cardListStyle = {
    // position from top of page = appbar height + filters height
    top: `${64 + filtersHeight}px`,
    height: `calc(100% - ${64 + 20 + MAP_ICON_SPACE_25 + filtersHeight}px)`,
  };
  const overlayStyle = {
    top: `${64 + 10 + filtersHeight}px`,
    height: `calc(100% - ${64 + 10 + filtersHeight}px)`,
  };
  const filtersRef = useRef(null);

  const { loading, data } = useQuery(COUNTRIES_QUERY, {
    variables: {
      filters,
      sortBy: sortType.value,
    },
  });

  const findCountry = (isoCode) => {
    return data?.countryStatistics.find((c) => c.isoCode === isoCode);
  };

  const zoomend = () => {
    if (overviewShouldOpen) {
      setOverviewOpen(true);
      overviewShouldOpen = false;
    }
  };

  const zoomToCountry = (country) => {
    overviewShouldOpen = true;
    const { geom, zoom } = country;
    const center = getLatLongFromPoint(geom);
    const { lng, lat } = map.getCenter();
    const currentZoom = map.getZoom();
    if (
      parseFloat(center[0]).toFixed(6) === lng.toFixed(6) &&
      parseFloat(center[1]).toFixed(6) === lat.toFixed(6) &&
      zoom === currentZoom
    ) {
      zoomend();
    } else {
      map.flyTo({
        center,
        zoom,
      });
    }
  };

  const handleCardClick = (country) => {
    setCountry(country);
    zoomToCountry(country);
  };

  const setFilters = (newFilters) => {
    if (newFilters.regions.length === 1 && filters.regions.length === 0) {
      const region = newFilters.regions[0];
      map.flyTo({
        center: region.coord,
        zoom: region.zoom,
      });
    } else if (newFilters.countries.length > filters.countries.length) {
      const lastIdx = newFilters.countries.length - 1;
      const lastCountryIsoCode = newFilters.countries[lastIdx].isoCode;
      const newSelectedCountry = findCountry(lastCountryIsoCode);
      handleCardClick(newSelectedCountry);
    }
    sessionStorage.setItem('countryOverviewFilters', JSON.stringify(newFilters));
    setFilterState(newFilters);
  };

  const clearFilters = () => {
    setFilters(defaultFilters);
    setOverviewOpen(false);
    map.resetZoom();
  };

  const removeLayerCallback = () => {
    setCountry(null);
    setClickedCountry(null);
  };

  const getDateText = (item) => item?.countryStatistics?.reportUpdatedAt || '-';

  useEffect(() => {
    setFiltersHeight(filtersRef.current.offsetHeight);
    setOverviewOpen(false);
  }, [filters]);

  // handle user clicks country
  useEffect(() => {
    // match selected country with clicked country, highlight, and simulate card click
    const newCountry = findCountry(clickedCountry?.layerCountry);
    if (newCountry) {
      // if user clicks on a country that has country and custom region boundaries, need to swap to correct boundary
      // ex. user clicks on USA but needs to highlight GoM custom boundary
      if (
        (newCountry.customRegion && clickedCountry.sourceLayer === 'countries') ||
        (!newCountry.customRegion && clickedCountry.sourceLayer === 'custom_regions')
      ) {
        setClickedCountry({
          ...clickedCountry,
          sourceLayer: clickedCountry.sourceLayer === 'countries' ? 'custom_regions' : 'countries',
        });
      } else {
        handleCardClick(newCountry);
      }
    }
  }, [clickedCountry]);

  // match clicked country with selected country and highlight
  useEffect(() => {
    if (clickedCountry?.layerCountry !== selectedCountry?.isoCode) {
      const newClickedCountry = {
        properties: {
          iso_3166_1_alpha_3: selectedCountry.isoCode,
        },
        sourceLayer: selectedCountry.customRegion ? 'custom_regions' : 'countries',
        layerCountry: selectedCountry.isoCode,
      };
      setClickedCountry(newClickedCountry);
    }
  }, [selectedCountry]);

  // set portfolio object for production graph
  const portfolios = useMemo(() => {
    return [getNewPortfolio(selectedCountry)];
  }, [selectedCountry]);

  const HeaderContainer = () => (
    <>
      <Grid item xs={2} style={{ display: 'flex' }}>
        <DownloadButton country={selectedCountry} />
      </Grid>
      <Grid item xs={4}>
        <LeadAnalyst country={selectedCountry} horizontal />
      </Grid>
    </>
  );

  const FiltersContainer = () => (
    <Grid
      ref={filtersRef}
      container
      className={clsx({
        [classes.filters]: true,
      })}
      spacing={1}
    >
      <Filters filters={filters} setFilters={setFilters} clearFilters={clearFilters} />
    </Grid>
  );

  const CardListContainer = () => (
    <Grid item className={clsx({ [classes.cardList]: true })} style={cardListStyle}>
      <VerticalCardList
        items={data ? data.countryStatistics : []}
        getDateText={getDateText}
        setSortType={setSortType}
        sortType={sortType}
        sortTypes={sortTypes}
        title="COUNTRY / AREA"
        loading={loading}
        onCardClick={handleCardClick}
        selectedItem={selectedCountry}
        rootInlineStyle={cardListStyle}
      />
    </Grid>
  );

  const layerClickCallback = (clickedCountry) => {
    setClickedCountry(clickedCountry);
  };

  // Global Search block
  // Getting the query parameters from the URL
  const searchParams = useGlobalSearchParameters();
  const gloSearchParams = getFulltextResultParametersFromSearch(searchParams);

  // Using hooking the zooming to the selected country
  useEffect(() => {
    if (map && gloSearchParams) {
      switch (gloSearchParams.action) {
        case GLOBAL_SEARCH_ACTION_DETAIL: {
          const country = findCountry(gloSearchParams.isoCode);
          if (country) {
            handleCardClick(country);
          }
          return;
        }
      }
      console.log('Unsupported action type', gloSearchParams);
    }
  }, [gloSearchParams]);

  return (
    <Grid className={classes.root} container direction="column">
      <FiltersContainer />
      <Map
        map={map}
        callbacks={{ zoomend }}
        layerClickCallback={layerClickCallback}
        setMap={setMap}
        settingsProps={{
          ...mapSettings,
          layerProps: {
            filters,
            defaultLayer: TOTAL_PRODUCTION_HEAT_MAP.value,
          },
          top: filtersHeight + 64,
        }}
        countryClickProps={{
          enabled: true,
          filters,
          selectedCountry,
        }}
        removeLayerCallback={removeLayerCallback}
        layers={LAYERS}
      />
      <CardListContainer />
      <InfoOverlay
        isOpen={overViewOpen}
        onClose={() => setOverviewOpen(false)}
        header={selectedCountry?.displayName}
        subHeader={`updated:\n${formatDateShort(getDateText(selectedCountry))}`}
        headerAdornment={<HeaderContainer />}
        inlineStyles={overlayStyle}
        styles={classes}
      >
        <Grid item xs={7} className={clsx(classes.overViewItem, classes.scroll)}>
          <SummaryList data={selectedCountry?.countryStatistics} items={summaryItems} />
          <Divider className={classes.divider} />
          <ProductionLineGraphProvider portfolios={[...portfolios]}>
            <ProductionLineGraph portfolio={portfolios[0]} />
          </ProductionLineGraphProvider>
        </Grid>
        <Grid item xs={5} className={clsx(classes.overViewItem, classes.scroll)}>
          <Links country={selectedCountry} />
        </Grid>
      </InfoOverlay>
    </Grid>
  );
};
